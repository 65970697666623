import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";

import { orderingPageSaga } from "../saga";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions, reducer, sliceKey } from "../slice";

import { Group as GroupProps } from "types";
import Item from "./Item";

const Group = memo(({index}: {index: number}) => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: orderingPageSaga });

    const orderingPage = useSelector(selectOrderingPage);
    const [group, setGroup] = useState<GroupProps | null>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (orderingPage.groups && orderingPage.groups[index]) {
            setGroup(orderingPage.groups[index]);
            if (orderingPage.groups[index].items) {
                setLoading(false);
            }
        }
    }, [index, orderingPage.groups])

    return(
        <div className="card group">
            <div className="card-body">
                <h4 className="card-title">{group?.name}</h4>
                <div className="row">
                    {loading ?
                        <div className="spinner-border text-primary" role="status"></div> :
                        (group?.items?.filter(
                            item => item.available == 'yes'
                        ).map(
                            item  => <Item {...item} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
});

const Products = () => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: orderingPageSaga });

    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const [groups, setGroups] = useState<GroupProps[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (orderingPage.groups) {
            setGroups(orderingPage.groups);
            setLoading(false);
        }
    }, [orderingPage.groups])

    const updateNotes = e => {
        const contacts = {...orderingPage.contacts};
        contacts.notes = e.target.value;
        dispatch(orderingPageActions.setContactDetails(contacts));
    }

    return (
        <div className="products">
            {loading ?
                <div className="spinner-border text-primary mx-3" role="status"></div> :
                (groups?.map((group, index) => (
                    (group.id === orderingPage.selectedGroup || orderingPage.selectedGroup === undefined) &&
                    <Group index={index} key={index} />
                )))
            }
            <div className="card group">
                <div className="card-body">
                    <h4 className="card-title">Notes</h4>
                    <div className="row">
                        <div className="form-group">
                            <textarea
                                maxLength   = {255}
                                rows        = {3}
                                className   = "form-control"
                                placeholder = {orderingPage.merchant.noteInstructions || "Additional Orders / Nearest Landmark / Requests / Special Instructions"}
                                value       = {orderingPage.contacts.notes}
                                onChange    = {updateNotes}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;