import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Group as GroupProps } from "types";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";
import { CartItem } from "../types";
import Item from "./Item";

interface CartGroup {
    group?: GroupProps
    items: CartItem[]
}

const Group = memo(({group, items }: CartGroup) => {
    return(
        <div className="card group">
            <div className="card-body">
                <h4 className="card-title">{group?.name}</h4>
                <div className="row">
                    {items.map(item  => <Item {...item} />)}
                </div>
            </div>
        </div>
    );
});

const Cart = () => {
    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const [groups, setGroups] = useState<CartGroup[]>();

    useEffect(() => {
        const cartGroups: CartGroup[] = [];
        if (orderingPage.cart && orderingPage.cart.length > 0) {
            for (const item of orderingPage.cart) {
                const group = orderingPage.groups?.find(group => group.id === item.item.store_category_id);
                const index = cartGroups.findIndex(cart => cart.group === group);
                if (index !== undefined && cartGroups[index]) {
                    cartGroups[index].items.push(item);
                } else {
                    cartGroups.push({group, items: [item]});
                }
            }
        }
        setGroups(cartGroups);
    }, [orderingPage])
    
    const updateNotes = e => {
        const contacts = {...orderingPage.contacts};
        contacts.notes = e.target.value;
        dispatch(orderingPageActions.setContactDetails(contacts));
    }

    if (orderingPage.cart.length == 0) {
        return <Redirect to={`/${orderingPage.merchant.username}`} />
    }
    return(
        <div className="cart">
            {groups?.map(group => <Group {...group} />)}
            <div className="card group">
                <div className="card-body">
                    <h4 className="card-title">Notes</h4>
                    <div className="row">
                        <div className="form-group">
                            <textarea
                                maxLength   = {255}
                                rows        = {3}
                                className   = "form-control"
                                placeholder = {orderingPage.merchant.noteInstructions || "Additional Orders / Nearest Landmark / Requests / Special Instructions"}
                                value       = {orderingPage.contacts.notes}
                                onChange    = {updateNotes}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart;